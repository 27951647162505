<!-- =========================================================================================
    File Name: Main.vue
    Description: Main layout
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div>
    <div class="layout--main" :class="[layoutTypeClass, navbarClasses, footerClasses, {'no-scroll': isAppPage}]">
      <div id="content-area">
        <div class="content-wrapper">
          <div class="flex items-start">
            <Sidebar :navMenuItems="navMenuItems" @toggleSidebar="isExpanded = $event" class="flex-shrink-0"></Sidebar>
            <div class="py-[32px] lg:px-[24px] px-[16px] h-[100vh] overflow-auto flex-grow sm:mb-0 mb-[86px]" :style="`${ isExpanded ? 'width: calc(100% - 270px)' : 'width: calc(100% - 82px)'}`" id="scrollContainer">
              <transition :name="routerTransition">
                <div v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="router-header flex flex-wrap items-center mb-6">
                  <div v-if="routeTitle" class="content-area__heading mr-4" :class="{'pr-4 border-0 md:border-r border-solid border-grey-light' : $route.meta.breadcrumb}">
                    <h2 class="mb-1">{{ $t(routeTitle) }}</h2>
                  </div>
                  <!-- BREADCRUMB -->
                  <vx-breadcrumb class="md:block hidden" :i18nData="i18nData" :store="$store" v-if="$route.meta.breadcrumb" :route="$route" :lang="$i18n.locale" :isRTL="$vs.rtl" />
                </div>
              </transition>

              <div class="back-to-top hidden" :class="userHasScrolled ? 'show-scroll' : 'hide-scroll'">
                <vs-button @click="toTop" icon-pack="feather" icon="icon-arrow-up" class="shadow-lg btn-back-to-top" />
              </div>
              <transition :name="routerTransition" mode="out-in">
                <router-view @changeRouteTitle="changeRouteTitle" @setAppClasses="(classesStr) => $emit('setAppClasses', classesStr)" />
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/layouts/merchant/Sidebar.vue'
import themeConfig         from '@/../themeConfig.js'
import i18nData            from '../../i18n/i18nData.js'
import { sendFulfillmentRequest }  from '../../http/axios/requestHelper.js'
import VueJwtDecode from 'vue-jwt-decode'
import { fcOperations, fcReceiving, fcInbounding, fcStoring, fcPacking, fcPicking } from '@/layouts/fulfillment/newFcNavMenuItems.js'

export default {
  components: {
    Sidebar
  },
  data () {
    return {
      navMenuItems      : [],
      dynamicWatchers   : {},
      footerType        : themeConfig.footerType  || 'static',
      navbarType        : themeConfig.navbarType  || 'floating',
      routerTransition  : themeConfig.routerTransition || 'none',
      routeTitle        : this.$route.meta.pageTitle,
      i18nData,
      userHasScrolled   : false,
      stockRequestsCount              : localStorage.getItem('createdStockRequests'),
      receivedStockRequestCount: localStorage.getItem('receivedStockRequests'),
      inboundedStockRequestCount: localStorage.getItem('inboundedStockRequest'),
      isExpanded: true,
      fcOperations,
      fcReceiving,
      fcInbounding,
      fcStoring,
      fcPacking,
      fcPicking
    }
  },
  watch: {
    '$route' () {
      this.routeTitle = this.$route.meta.pageTitle
    }
  },
  computed: {
    bodyOverlay () { return this.$store.state.bodyOverlay },
    contentAreaClass () {
      if (this.mainLayoutType === 'vertical') {
        if      (this.verticalNavMenuWidth === 'default') return 'content-area-reduced'
        else if (this.verticalNavMenuWidth === 'reduced') return 'content-area-lg'
      }
    },
    footerClasses () {
      return {
        'footer-hidden': this.footerType === 'hidden',
        'footer-sticky': this.footerType === 'sticky',
        'footer-static': this.footerType === 'static'
      }
    },
    isAppPage () {
      return this.$route.meta.no_scroll
    },
    isThemeDark ()     { return this.$store.state.theme === 'dark' },
    layoutTypeClass () { return `main-${this.mainLayoutType}`      },
    mainLayoutType ()  { return this.$store.state.mainLayoutType   },
    navbarClasses ()   {
      return {
        'navbar-hidden'   : this.navbarType === 'hidden',
        'navbar-sticky'   : this.navbarType === 'sticky',
        'navbar-static'   : this.navbarType === 'static',
        'navbar-floating' : this.navbarType === 'floating'
      }
    },
    verticalNavMenuWidth () { return this.$store.state.verticalNavMenuWidth },
    windowWidth ()          { return this.$store.state.windowWidth }
  },
  methods: {
    toTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    changeRouteTitle (title) {
      this.routeTitle = title
    },
    loadStockRequests () {
      switch (this.$store.state.AppActiveUser.userRole) {
      case 'fc-inbounding-agent':
        this.loadStockRequestsFilterd('received')
        break
      case 'fc-storing-agent':
        this.loadStockRequestsFilterd('inbounded')
        break
      case 'fc-operations-supervisor':
        this.loadStockRequestsFilterd('submitted')
        break
      case 'fc-receiving-agent':
        this.loadStockRequestsFilterd('submitted')
        break
      default:
        break
      }
    },
    loadStockRequestsFilterd (status) {
      sendFulfillmentRequest(false, false, this, `api/v1/fc/stock-requests/?status=${status}`, 'get', null, true,
        (response) => {
          this.navMenuItems = []
          switch (this.$store.state.AppActiveUser.userRole) {
          case 'fc-inbounding-agent':
            localStorage.setItem('receivedStockRequests', response.data.count)
            this.navMenuItems = this.fcInbounding
            break
          case 'fc-storing-agent':
            localStorage.setItem('inboundedStockRequest', response.data.count)
            this.navMenuItems = this.fcStoring
            break
          case 'fc-operations-supervisor':
            localStorage.setItem('createdStockRequests', response.data.count)
            this.navMenuItems = this.fcOperations
            break
          case 'fc-receiving-agent':
            localStorage.setItem('createdStockRequests', response.data.count)
            this.navMenuItems = this.fcReceiving
            break
          default:
            break
          }
          localStorage.setItem('receivedStockRequests', response.data.count)
          this.navMenuItems = this.fcInbounding
        }
      )
    }
  },
  created () {
    const userInfo = localStorage.getItem('accessToken') ? VueJwtDecode.decode(localStorage.getItem('accessToken')) : {}
    this.$store.dispatch('updateUserInfo', {
      displayName: userInfo.name,
      email: userInfo.email,
      photoURL: userInfo.picture,
      providerId: userInfo.sub.length > 0 ? userInfo.sub.substr(0, userInfo.sub.indexOf('|')) : '',
      uid: userInfo.sub,
      userRole: userInfo[`${process.env.VUE_APP_API_URL}/role`][0]
    })
    this.$acl.change(this.$store.state.AppActiveUser.userRole)
    const query = {
      ...this.$route.query,
      page: Number(this.$route.query.page) > 0 ? this.$route.query.page : 1
    }
    if (this.$route.query && this.$route.query.page < 0) {
      this.$router.push({
        query
      }).catch(() => {})
    }
    localStorage.setItem('multipleWarehouses', true)
    const fcRoles = ['fc-storing-agent', 'fc-inbounding-agent', 'fc-receiving-agent', 'fc-operations-supervisor', 'fc-packing-agent', 'fc-picking-agent']
    if (fcRoles.includes(this.$store.state.AppActiveUser.userRole)) {
      setInterval(() => {
        this.navMenuItems = []
        this.stockRequestsCount = localStorage.getItem('createdStockRequests')
        this.receivedStockRequestCount = localStorage.getItem('receivedStockRequests')
        this.inboundedStockRequestCount = localStorage.getItem('inboundedStockRequest')
        this.navMenuItems = []
        switch (this.$store.state.AppActiveUser.userRole) {
        case 'fc-receiving-agent':
          this.navMenuItems = this.fcReceiving
          break
        case 'fc-inbounding-agent':
          this.navMenuItems = this.fcInbounding
          break
        case 'fc-storing-agent':
          this.navMenuItems = this.fcStoring
          break
        case 'fc-packing-agent':
          this.navMenuItems = this.fcPacking
          break
        case 'fc-picking-agent':
          this.navMenuItems = this.fcPicking
          break
        default:
          this.navMenuItems = this.fcOperations
          break
        }
      }, 1000)
      this.loadStockRequests()
    }
    if (this.$cookies.get('language') === 'en') {
      this.$i18n.locale = 'en'
      this.$vs.rtl = false
    } else {
      this.$i18n.locale = 'ar'
      this.$vs.rtl = true
    }
    window.onscroll = () => {
      if (document.documentElement.scrollTop > 300) {
        this.userHasScrolled = true
      } else {
        this.userHasScrolled = false
      }
    }
  }
}
</script>

<style>
iframe[title="Message from company"],
iframe[title="Close message"] {
  display: none !important;
}
#launcher {
  display: none;
}
#fc_frame {
  display: none;
}
.back-to-top {
  position: fixed;
  z-index: 99999;
  bottom: 35px;
  right: 35px;
  transition: 0.5s;
}
.hide-scroll {
  opacity: 0;
}
.show-scroll {
  opacity: 1;
}
</style>
