// =============================================================================
// FC OPERATIONS LAYOUT MENU
// =============================================================================
const fcOperations = [
  {
    id: 1,
    url: '/fc-operations-supervisor/dashboard',
    name: 'Dashboard',
    i18n: 'Dashboard',
    slug: 'fc-operations-supervisor-dashboard',
    items: [],
    icon: 'HomeIcon',
    iconLibrary: 'feather'
  },
  {
    id: 2,
    header: 'FC OPS',
    shortHeader: 'FOH',
    i18n: 'Stock',
    icon: 'ShoppingCartIcon',
    iconLibrary: 'feather',
    items: [
      {
        id: 1,
        name: 'Stock Requests',
        i18n: 'Stock Requests',
        slug: 'fc-operations-supervisor-fulfillment-stock-requests',
        icon: 'ShoppingCartIcon',
        iconLibrary: 'feather',
        submenu: [
          {
            url: '/fc-operations-supervisor/stock-requests',
            name: 'WROs',
            i18n: 'WROs',
            slug: 'fc-operations-supervisor-fulfillment-stock-requests',
            icon: 'arrow-right',
            iconLibrary: 'phosphor'
          },
          {
            url: '/fc-operations-supervisor/unidentified-stock-requests',
            name: 'UROs',
            i18n: 'UROs',
            slug: 'fc-operations-supervisor-fulfillment-unidentified-stock-requests',
            icon: 'arrow-right',
            iconLibrary: 'phosphor'
          }
        ]
      },
      {
        id: 2,
        url: '/fc-operations-supervisor/stock-recalls',
        name: 'Stock Recalls',
        i18n: 'Stock Recalls',
        slug: 'fc-operations-supervisor-stock-recalls',
        items: [],
        icon: 'ShoppingCartIcon',
        iconLibrary: 'feather'
      },
      {
        id: 3,
        url: '/fc-operations-supervisor/inventory',
        name: 'Inventory',
        i18n: 'Inventory',
        slug: 'fc-operations-supervisor-inventory',
        items: [],
        icon: 'ListIcon',
        iconLibrary: 'feather'
      }
    ]
  },
  {
    id: 3,
    header: 'Fullfilment Orders',
    shortHeader: 'FO',
    i18n: 'Shipping',
    icon: 'ShoppingCartIcon',
    iconLibrary: 'feather',
    items: [
      {
        id: 4,
        name: 'Orders',
        i18n: 'Orders',
        slug: 'fc-operations-supervisor-orders',
        icon: 'ShoppingCartIcon',
        iconLibrary: 'feather',
        submenu: [
          {
            url: '/fc-operations-supervisor/fulfillment/outbound-orders',
            name: 'Outbound Orders',
            i18n: 'Outbound Orders',
            icon: 'arrow-right',
            iconLibrary: 'phosphor',
            slug: 'fc-operations-supervisor-fulfillment-outbound-orders'
          },
          {
            url: '/fc-operations-supervisor/fulfillment/inbound-orders',
            name: 'Inbound Orders',
            i18n: 'Inbound Orders',
            icon: 'arrow-right',
            iconLibrary: 'phosphor',
            slug: 'fc-operations-supervisor-fulfillment-inbound-orders'
          }
        ]
      }
    ]
  },
  {
    id: 4,
    header: 'Storage',
    shortHeader: 'SU',
    i18n: 'Storage',
    icon: 'ShoppingCartIcon',
    iconLibrary: 'feather',
    items: [
      {
        id: 5,
        name: 'Storage Units',
        i18n: 'Storage Units',
        slug: 'fc-operations-supervisor-storage',
        icon: 'ShoppingCartIcon',
        iconLibrary: 'feather',
        submenu: [
          {
            url: '/fc-operations-supervisor/totes',
            name: 'Tote',
            i18n: 'Tote',
            slug: 'fc-operations-supervisor-totes',
            icon: 'arrow-right',
            iconLibrary: 'phosphor'
          },
          {
            url: '/fc-operations-supervisor/boxes',
            name: 'Box',
            i18n: 'Box',
            slug: 'fc-operations-supervisor-boxes',
            icon: 'arrow-right',
            iconLibrary: 'phosphor'
          },
          {
            url: '/fc-operations-supervisor/pallets',
            name: 'Pallet',
            i18n: 'Pallet',
            slug: 'fc-operations-supervisor-pallets',
            icon: 'arrow-right',
            iconLibrary: 'phosphor'
          },
          {
            url: '/fc-operations-supervisor/shelves',
            name: 'Shelf',
            i18n: 'Shelf',
            slug: 'fc-operations-supervisor-shelves',
            icon: 'arrow-right',
            iconLibrary: 'phosphor'
          }
        ]
      },
      {
        id: 6,
        url: '/fc-operations-supervisor/bins',
        name: 'Bins',
        i18n: 'bins',
        slug: 'fc-operations-supervisor-bins',
        items: [],
        icon: 'ArchiveIcon',
        iconLibrary: 'feather'
      }
    ]
  },
  {
    id: 5,
    url: '/fc-operations-supervisor/picking-bins',
    name: 'Picking Bins',
    i18n: 'Picking Bins',
    slug: 'fc-operations-supervisor-picking-bins',
    icon: 'ArchiveIcon',
    iconLibrary: 'feather'
  },
  {
    id: 6,
    url: '/fc-operations-supervisor/task-lists',
    name: 'Task Lists',
    query: '?tab=Picking List',
    i18n: 'Task Lists',
    icon: 'ArchiveIcon',
    slug: 'fc-operations-task-lists',
    iconLibrary: 'feather'
  }
]

// =============================================================================
// FC RECEVING LAYOUT MENU
// =============================================================================
const fcReceiving = [
  {
    id: 1,
    url: '/fc-receiving-agent/dashboard',
    name: 'Dashboard',
    i18n: 'Dashboard',
    slug: 'fc-receiving-agent-dashboard',
    items: [],
    icon: 'HomeIcon',
    iconLibrary: 'feather'
  },
  {
    id: 2,
    header: 'Stocks',
    shortHeader: 'SR',     
    i18n: 'Stocks',
    icon: 'PackageIcon',
    iconLibrary: 'feather',
    items: [
      {
        id: 1,
        name: 'Stock Requests',
        i18n: 'Stock Requests',
        slug: 'fc-receiving-agent-stock-requests',
        icon: 'ShoppingCartIcon',
        iconLibrary: 'feather',
        submenu: [
          {
            url: '/fc-receiving-agent/stock-requests',
            name: 'WROs',
            i18n: 'WROs',
            slug: 'fc-receiving-agent-fulfillment-stock-requests',
            icon: 'arrow-right',
            iconLibrary: 'phosphor'
          },
          {
            url: '/fc-receiving-agent/unidentified-stock-requests',
            name: 'UROs',
            i18n: 'UROs',
            slug: 'fc-receiving-agent-fulfillment-unidentified-stock-requests',
            icon: 'arrow-right',
            iconLibrary: 'phosphor'
          }
        ]
      }
    ]
  }
]

// =============================================================================
// FC INBOUNDING LAYOUT MENU
// =============================================================================
const fcInbounding = [
  {
    id: 1,
    url: '/fc-inbounding-agent/dashboard',
    name: 'Dashboard',
    i18n: 'Dashboard',
    slug: 'fc-inbounding-agent-dashboard',
    items: [],
    icon: 'HomeIcon',
    iconLibrary: 'feather'
  },
  {
    id: 2,
    header: 'Stocks',
    shortHeader: 'SR',     
    i18n: 'Stocks',
    icon: 'PackageIcon',
    iconLibrary: 'feather',
    items: [
      {
        id: 1,
        name: 'FC INBOUNDING',
        i18n: 'Stock Requests',
        slug: 'fc-inbounding-agent-fulfillment-stock-requests',
        icon: 'ShoppingCartIcon',
        iconLibrary: 'feather',
        submenu: [
          {
            url: '/fc-inbounding-agent/stock-requests',
            name: 'WROs',
            i18n: 'WROs',
            slug: 'fc-inbounding-agent-fulfillment-stock-requests',
            icon: 'arrow-right',
            iconLibrary: 'phosphor'
          },
          {
            url: '/fc-inbounding-agent/unidentified-stock-requests',
            name: 'UROs',
            i18n: 'UROs',
            slug: 'fc-inbounding-agent-fulfillment-unidentified-stock-requests',
            icon: 'arrow-right',
            iconLibrary: 'phosphor'
          }
        ]
      }
    ]
  },
  {
    id: 3,
    url: '/fc-inbounding-agent/bins',
    name: 'Bins',
    i18n: 'bins',
    icon: 'ArchiveIcon',
    slug: 'fc-inbounding-agent-bins',
    items: [],
    iconLibrary: 'feather'
  }
]

// =============================================================================
// FC STORING LAYOUT MENU
// =============================================================================
const fcStoring = [
  {
    id: 1,
    url: '/fc-storing-agent/dashboard',
    name: 'Dashboard',
    i18n: 'Dashboard',
    slug: 'fc-storing-agent-dashboard',
    items: [],
    icon: 'HomeIcon',
    iconLibrary: 'feather'
  },
  {
    id: 2,
    header: 'FC STORING',
    shortHeader: 'FOS',
    i18n: 'Stock',
    icon: 'ShoppingCartIcon',
    iconLibrary: 'feather',
    items: [
      {
        id: 1,
        name: 'Stock Requests',
        i18n: 'Stock Requests',
        slug: 'fc-operations-supervisor-fulfillment-stock-requests',
        icon: 'ShoppingCartIcon',
        iconLibrary: 'feather',
        submenu: [
          {
            url: '/fc-storing-agent/stock-requests',
            name: 'WROs',
            i18n: 'WROs',
            slug: 'fc-storing-agent-fulfillment-stock-requests',
            icon: 'arrow-right',
            iconLibrary: 'phosphor'
          },
          {
            url: '/fc-storing-agent/unidentified-stock-requests',
            name: 'UROs',
            i18n: 'UROs',
            slug: 'fc-storing-agent-fulfillment-unidentified-stock-requests',
            icon: 'arrow-right',
            iconLibrary: 'phosphor'
          }
        ]
      }
    ]
  },
  {
    id: 2,
    header: 'Storage',
    shortHeader: 'SU',
    i18n: 'Storage',
    icon: 'ShoppingCartIcon',
    iconLibrary: 'feather',
    items: [
      {
        id: 2,
        name: 'Storage Units',
        i18n: 'Storage Units',
        slug: 'fc-storing-agent-fulfillment-storage',
        icon: 'ShoppingCartIcon',
        iconLibrary: 'feather',
        submenu: [
          {
            url: '/fc-storing-agent/totes',
            name: 'Tote',
            i18n: 'Tote',
            slug: 'fc-storing-agent-totes',
            icon: 'arrow-right',
            iconLibrary: 'phosphor'
          },
          {
            url: '/fc-storing-agent/boxes',
            name: 'Box',
            i18n: 'Box',
            slug: 'fc-storing-agent-boxes',
            icon: 'arrow-right',
            iconLibrary: 'phosphor'
          },
          {
            url: '/fc-storing-agent/pallets',
            name: 'Pallet',
            i18n: 'Pallet',
            slug: 'fc-storing-agent-pallets',
            icon: 'arrow-right',
            iconLibrary: 'phosphor'
          },
          {
            url: '/fc-storing-agent/shelves',
            name: 'Shelf',
            i18n: 'Shelf',
            slug: 'fc-storing-agent-shelves',
            icon: 'arrow-right',
            iconLibrary: 'phosphor'
          }
        ]
      },
      {
        id: 3,
        url: '/fc-storing-agent/bins',
        name: 'Bins',
        i18n: 'bins',
        slug: 'fc-storing-agent-bins',
        items: [],
        icon: 'ArchiveIcon',
        iconLibrary: 'feather'
      }
    ]
  }
]

// =============================================================================
// FC PACKING LAYOUT MENU
// =============================================================================
const fcPacking = [
  {
    id: 1,
    url: '/fc-packing-agent/dashboard',
    name: 'Dashboard',
    i18n: 'Dashboard',
    slug: 'fc-packing-agent-dashboard',
    items: [],
    icon: 'HomeIcon',
    iconLibrary: 'feather'
  },
  {
    id: 2,
    url: '/fc-packing-agent/picking-bins',
    name: 'Picking Bins',
    i18n: 'Picking Bins',
    slug: 'fc-packing-agent-picking-bins',
    items: [],
    icon: 'ArchiveIcon',
    iconLibrary: 'feather'
  },
  {
    id: 3,
    url: '/fc-packing-agent/packing-tasklists',
    name: 'Packing Tasklists',
    i18n: 'Packing Tasklists',
    slug: 'fc-packing-agent-packing-tasklists',
    items: [],
    icon: 'ArchiveIcon',
    iconLibrary: 'feather'
  }
]

// =============================================================================
// FC PICKING LAYOUT MENU
// =============================================================================
const fcPicking = [
  {
    id: 1,
    url: '/fc-picking-agent/dashboard',
    name: 'Dashboard',
    i18n: 'Dashboard',
    slug: 'fc-picking-agent-dashboard',
    items: [],
    icon: 'HomeIcon',
    iconLibrary: 'feather'
  },
  {
    id: 2,
    url: '/fc-picking-agent/picking-bins',
    name: 'Picking Bins',
    i18n: 'Picking Bins',
    slug: 'fc-picking-agent-picking-bins',
    items: [],
    icon: 'ArchiveIcon',
    iconLibrary: 'feather'
  },
  {
    id: 3,
    url: '/fc-picking-agent/picking-tasklists',
    name: 'Picking Task Lists',
    i18n: 'Picking Task Lists',
    slug: 'fc-picking-agent-picking-tasklists',
    items: [],
    icon: 'ArchiveIcon',
    iconLibrary: 'feather'
  }
]

export { fcOperations, fcReceiving, fcInbounding, fcStoring, fcPacking, fcPicking }